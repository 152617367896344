import React, { Component, Fragment } from "react";
import firebase from "./firebase";
import { Route, Switch, withRouter } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css'

import HeaderComponent from "./CommonComponents/HeaderComponent/Header";
import FooterComponent from "./CommonComponents/FooterComponent/Footer";
import AdminMain from "./AdminComponents/AdminMain";
import PhoneAuth from "./CommonComponents/AuthComponent/PhoneAuth";
import HomePage from "./CommonComponents/HomePage";
import ProfilePage from "./CommonComponents/AuthComponent/ProfilePage";
import MoneyComponent from "./CommonComponents/userComponents/MoneyComponent";
import { ToastContainer } from "react-toastify";


class App extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      userData: "",
      loggedIn: false
    };
  }

  componentDidMount() {
    const messaging = firebase.messaging();
    messaging.getToken().then((currentToken) => {
      if (currentToken) {
      } else {
        // Show permission request UI
        console.log('No registration token available. Request permission to generate one.');
        // ...
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
      // ...
    });
    firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        let walletBalance=0
        let userObj=await user.getIdTokenResult()
        let walletUrl='users/'+user.uid+'/wallet'
        console.log(user)
        walletBalance=await new Promise(resolve=>{
          let ref=firebase.database().ref(walletUrl)
          ref.on('value',(snapshot)=>{
            if(snapshot.val()!=null){
              resolve(snapshot.val().balance||0)
            }
            else resolve(0)
          })
        })
        console.log(2)
        let userObject={
          admin:userObj.claims.admin|| false,
          phoneNumber:user.phoneNumber,
          displayName:user.displayName||"",
          photoURL:user.photoURL||"",
          userId:user.uid,
          idToken:userObj.token,
          walletBalance:walletBalance
        }
        
        this.setState({ 
          userData: userObject ,
          loggedIn:true
        });
        // this.props.history.push("/list-movies");
      } else {
        this.setState({ userData: "" ,loggedIn:false});
        //this.props.history.push("/login");
      }
    });
  }
  
  render() {
    function Home() {
      return <h2>Home</h2>;
    }
    
    function About() {
      return <h2>About</h2>;
    }
    
    function Users() {
      return <h2>Users</h2>;
    }
    console.log(this.state)
    return (
      <Fragment>
        <header>
          <HeaderComponent userData={this.state.userData} />
        </header>
        <main style={{minHeight:"500px",backgroundColor:"#FFFFFF"}}>
          <ToastContainer />
          <Switch>
            {/* <Route path="/" render={()=> <HomePage userData={this.state.userData} />} /> */}
            <Route path="/" exact component={HomePage}/>
            {this.state.userData.toString()}?
            <Route path="/profile" exact render={()=> <ProfilePage userData={this.state.userData} />} />
            <Route path="/controlCenter" render={()=> <AdminMain userData={this.state.userData} />} />
            <Route path="/myAccount" render={()=> <MoneyComponent userData={this.state.userData} />} />
            :<Route path="/login" component={PhoneAuth} />
        </Switch>
        </main>
        {/* <div class="btn">
          <span>
            <i class="fa fa-bell btn-bell"></i>
          </span>
        </div> */}
        {/* footer component */}
        <FooterComponent user={this.state.userData} />
      </Fragment>
    );
  }

}

export default withRouter(App);
