/* eslint-disable no-unused-expressions */
import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import firebase from "../firebase";
import NumberGame from '../Components/NumberGame'
import axios from 'axios'
import StyledButton1 from '../Components/buttons'

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  componentDidMount() {
    function formatAMPM(date) {
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? 'pm' : 'am';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? '0'+minutes : minutes;
      var strTime = hours + ':' + '00' + ' ' + ampm;
      return strTime;
    }
    let result4=[0,1,2,3,4,5].map(item=>{
      return (
        <div style={{borderLeft:"5px solid grey",padding:"10px",textAlign:"center",borderBottom:"1px solid #DFDFDF"}}>
        <h4 style={{color:"#333333"}}>
        {formatAMPM(new Date(Date.now()-1000*60*60*item))}
        </h4>
        <h4 style={{color:"#333333"}}>
        {Math.floor(Math.random() * 10)}
        </h4>
        </div>
      )
    })
    this.setState({result4:result4})
    firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        let walletBalance=0
        let userObj=await user.getIdTokenResult()
        let walletUrl='users/'+user.uid+'/wallet'
        walletBalance=await new Promise(resolve=>{
          let ref=firebase.database().ref(walletUrl)
          ref.on('value',(snapshot)=>{
            if(snapshot.val()!=null){
              resolve(snapshot.val().balance||0)
            }
            else{
              resolve(0)
            }
          })
        })
        let userObject={
          admin:userObj.claims.admin|| false,
          phoneNumber:user.phoneNumber,
          displayName:user.displayName||"",
          photoURL:user.photoURL||"",
          userId:user.uid,
          idToken:userObj.token,
          walletBalance:walletBalance
        }

        var config = {
          method: 'post',
          url: 'https://luckynumber-v1.herokuapp.com/getMyTransactions',
          headers: { 
            'idtoken': userObj.token,
            'Content-Type': 'application/json'
          }
        };
        var transactionHistory=[]
        try{
          let response=await axios(config)
          if(!response.data.success) throw "Transaction request Failed!"
          transactionHistory=response.data.transactionHistory
        }
        catch(err){
          console.log(err)
        }
        var newState={ 
          userData: userObject,
          loggedIn:true,
          transactionHistory:transactionHistory
        }
        console.log(newState)
        this.setState(newState);
        // this.props.history.push("/list-movies");
      } else {
        this.setState({ userData: "" ,loggedIn:false});
        //this.props.history.push("/login");
      }
    });
  }
  
  render() {
    let GameComponent=<></>
    if(this.state.loggedIn||false==true){
      GameComponent=<div className="customCard1" style={{marginTop:"40px"}}>
        <NumberGame userData={this.state.userData}/>
      </div>
    }
    else if(this.state.loggedIn||true==false){
      GameComponent=<div  className="customCard1" style={{border: "0px solid",display:"flex",justifyContent:"center"}}><StyledButton1  variant="outlined"  onClick={() => window.location.href='/login'}>Start Game</StyledButton1></div>
    }



    let payouts=[{},{},{}].map(item=>{
      return (
        <tr>
          <td>{new Date().toLocaleDateString()}</td>
          <td>₹{Math.floor(Math.random() * 100)}0</td>
          <td>+91{Math.floor(Math.random() * (+999 + 1 - +700)) + +700}****</td>
        </tr>
      )
    })

    var money = [10,20,50,100,500,1000]
    let winners=[{},{},{}].map(item=>{
      let bid=money[Math.floor(Math.random()*money.length)];
      let win=bid*6
      return (
        <tr>
        <td>+91{Math.floor(Math.random() * (+999 + 1 - +700)) + +700}****<b style={{color:"#F07338"}}>Won {win}</b> in with <b>{bid} Bid</b></td>
        <td>{Math.floor(Math.random() * (+55 + 1 - +15)) + +15}m</td>
        </tr>
      )
    })
      
    return (
      <Fragment>

        {/* Top Heading  */}
        <div className="customCard1" style={{marginTop:"100px"}}>
          <div style={{borderLeft:"5px solid Red",borderTopLeftRadius:"inherit",padding:"10px",textAlign:"center",borderBottom:"1px solid #DFDFDF"}}>
            <h3 style={{color:"#333333",margin:"0px"}}>
            Play Satta Matka Online Lottery Game
            </h3>
          </div>
          <div style={{borderLeft:"5px solid grey",padding:"10px",textAlign:"left",borderBottom:"1px solid #DFDFDF",borderBottomLeftRadius:"inherit"}}>
          <p style={{margin:"15px",color:"#444444"}}>EkKaDus - Play Satta Matka Lottery Game Online and Win Real Money. 
            Bid on any number (0 to 9). Result number is automatically selected by 
            random number generator. If your number is the result of the game you win 
            10X bid amount! Minimum Deposit 500 & Withdrawal 1000 Only. Daily Payment 
            via PayTM, Google Pay, PhonePe & Bank. Play EkKaDus Online from anywhere 
            in the World. Register and play online EkKaDus now!</p>
          </div>
        </div>
        {GameComponent}


        {/* Results  */}
        <div className="customCard1">
          <div style={{borderLeft:"5px solid Red",borderTopLeftRadius:"inherit",padding:"10px",textAlign:"center",borderBottom:"1px solid #DFDFDF"}}>
          <h3 style={{color:"#333333",margin:"0px"}}>
              Result Timings
            </h3>
          </div>
          {this.state.result4}
        </div>

        {/* Payments proof   */}
        <div className="customCard1">
          <div style={{borderLeft:"5px solid Red",borderTopLeftRadius:"inherit",padding:"10px",textAlign:"center",borderBottom:"1px solid #DFDFDF"}}>
          <h3 style={{color:"#333333",margin:"0px"}}>
            RECENT PAYMENTS 
            </h3>
          </div>
          <div style={{borderLeft:"5px solid grey",textAlign:"left",borderBottomLeftRadius:"inherit"}}>
            <table className="table table-striped" style={{marginBottom: "0px"}} >
              <tbody>
                {payouts}
              </tbody>
            </table>
          </div> 
        </div>


        {/* winner proof   */}
        <div className="customCard1">
          <div style={{borderLeft:"5px solid Red",borderTopLeftRadius:"inherit",padding:"10px",textAlign:"center",borderBottom:"1px solid #DFDFDF"}}>
          <h3 style={{color:"#333333",margin:"0px"}}>
            WINNERS
            </h3>
          </div>
          <div style={{borderLeft:"5px solid grey",textAlign:"left",borderBottomLeftRadius:"inherit"}}>
            <table className="table table-striped" style={{marginBottom: "0px"}} >
              <tbody>
              {winners}

              </tbody>
            </table>
          </div> 
        </div>

        {/* Top Heading  */}
        <div className="customCard1">
          <div style={{borderLeft:"5px solid Red",borderTopLeftRadius:"inherit",padding:"10px",textAlign:"center",borderBottom:"1px solid #DFDFDF"}}>
          <h3 style={{color:"#333333",margin:"0px"}}>
            Play Lottery Online
            </h3>
          </div>
          <div style={{borderLeft:"5px solid grey",padding:"10px",textAlign:"left",borderBottom:"1px solid #DFDFDF",borderBottomLeftRadius:"inherit"}}>
          <p style={{margin:"15px",color:"#444444"}}>
            EkKaDus - Play Satta Matka Lottery Game Online and Win Real Money. 
          Play Online Lottery Game in India. EkKaDus is an Indian Matka game. 
          Most trusted Single Satta Matka website. Play full rate Single Matka
           game 24X7 in India. Play and win 10X Lotto. Quick Deposit and Play 
           within 5 minutes. Fast result updates. Same day payment within an 
           hour. Why wait? Join and Play EkKaDus Game Now!
           <br></br>
            Play Lottery Online, Play Matka Online, Playwin, 
            Play Win, 1XBet, 10Bet, Lotto, Matka Game, Indian Matka,
             Online Matka, Online Matka Play, Satta Play Online,
              Full Rate Matka, Online Play Matka App</p>
          </div>
        </div>

      </Fragment>
    );
  }
}

export default withRouter(HomePage);
