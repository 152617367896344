import firebase from "firebase";

import "firebase/auth"; //auth
import "firebase/database"; //database can like sql , mongodb //firebase is no sql type
import "firebase/storage"; //storage ///images , videos , photos , pdf , fonts

const firebaseConfig = {
  apiKey: "AIzaSyAy05whyNR5ihRjMTCv7QVqVe3C2-LzfEY",
  authDomain: "luckywinner-ad846.firebaseapp.com",
  databaseURL: "https://luckywinner-ad846-default-rtdb.firebaseio.com",
  projectId: "luckywinner-ad846",
  storageBucket: "luckywinner-ad846.appspot.com",
  messagingSenderId: "53412580531",
  appId: "1:53412580531:web:0c762ae891f184d3b934c6"
};

//initialize firebase and react application
firebase.initializeApp(firebaseConfig);


export default firebase;
