import React from 'react';
import { makeStyles, withStyles,createMuiTheme } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TextField from '@material-ui/core/TextField';
import { red } from '@material-ui/core/colors';
import StyledButton1 from '../Components/buttons'
import { Link } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const CssTextField = withStyles({
  root: {
    marginTop:'0px',
    '& label.Mui-focused': {
      color: '#F07338',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#4D4D4D',
      },
      '& fieldset legend': {
        width:'60px'
      },
      '&:hover fieldset': {
        borderColor: '#000000',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#F07338',
      },
    }
  },
})(TextField);

const AntTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8'
  },
  indicator: {
    backgroundColor: '#F07338',
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontSize:16,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    '&:hover': {
      color: '#00000',
      opacity: 1,
    },
    '&$selected': {
      color: '#F07338',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#00000',
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  padding: {
    padding: theme.spacing(3),
  },
  demo1: {
    backgroundColor: theme.palette.background.paper,
  },
  demo2: {
    backgroundColor: '#2e1534',
  },
}));



export default function CustomizedTabs(userData) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [getWithDrawAmount, setWithDrawAmount] = React.useState(100);
  const [getFromDate, setFromDate] = React.useState(new Date(Date.now()-1000*60*60*24*2).toString());
  const [getToDate, setToDate] = React.useState(new Date().toString());
  const [getWithdrawRequestArray, setWithdrawRequestArray] = React.useState([]);
  const [getPAYTMNumber, setPAYTMNumber] = React.useState("+91");
  const [getupiId, setupiId] = React.useState("");
  const [getNote, setNote] = React.useState("");

  const handleChangeWithDrawAmount = (event) => {
    setWithDrawAmount(event.target.value);
  };

  const handleChangepaytmNumber = (event) => {
    setPAYTMNumber(event.target.value);
  };

  const handleChangeFromDate = (event) => {
    setFromDate(event.target.value);
  };

  const handleChangeToDate = (event) => {
    setToDate(event.target.value);
  };


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function AddMoneyRequest() {
    let idToken=userData.userData.idToken
    var data = JSON.stringify(
      {
        "phoneNumber":getPAYTMNumber,
        "amount":Number(getWithDrawAmount)
      }
    );
    var config = {
      method: 'post',
      url: 'https://luckynumber-v1.herokuapp.com/addMoneyToWallet',
      headers: { 
        'idtoken': idToken, 
        'Content-Type': 'application/json'
      },
      data : data
    };

    axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
      toast("💸 Money Added")
    })
    .catch(function (error) {
      console.log(error);
      toast("❌ Failed To add Money")
    });
  }

  function getWithDrawRequests() {
    let idToken=userData.userData.idToken
    if(!getFromDate&&!getToDate){
      toast("🔢 Select Date First")
      return 
    }
    var data = JSON.stringify({
      "fromDate":getFromDate,
      "toDate":getToDate
    });
    var config = {
      method: 'post',
      url: 'https://luckynumber-v1.herokuapp.com/getWithdrawRequests',
      headers: { 
        'idtoken': idToken, 
        'Content-Type': 'application/json'
      },
      data : data
    };

    axios(config)
    .then(function (response) {
      setWithdrawRequestArray(response.data.withdraws)
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  function MarkCompleteWithdrawRequest(withdrawRequestId) {
    let idToken=userData.userData.idToken
    var data = JSON.stringify({
      "withdrawRequestId":withdrawRequestId,
      "status":"COMPLETE"
    });

    var config = {
      method: 'post',
      url: 'https://luckynumber-v1.herokuapp.com/markWithdrawRequest',
      headers: { 
        'idtoken': idToken, 
        'Content-Type': 'application/json'
      },
      data : data
    };

    axios(config)
    .then(function (response) {
      if(response.data.success){
        toast.success('👍🏻 Money Withdrawal Complete', {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
      }
      else{
        toast.warn(`❌ ${response.data.message}`, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
      }
    })
    .catch(function (error) {
      toast.error(`❌ Something Went Wrong!!`, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    });

  }

  let result2=getWithdrawRequestArray.map(item=>{
    return <>
      <tr key={item.withdrawRequestId}>
        <td>{new Date(item.timeStamp).toString().split('GMT')[0]}</td>
        <td><h4>{item.withdrawType}</h4><h4>{item.amount}₹</h4></td>
        <td>{item.phoneNumber||item.upiId}</td>
        <td><StyledButton1 variant="outlined" onClick={()=>MarkCompleteWithdrawRequest(item.withdrawRequestId)}>Settle</StyledButton1></td>
      </tr>
    </>
  })

  return (
    <div className="customCard1" style={{marginTop:"100px",backgroundColor:"#fff4f0"}}>
      <div className={classes.root} style={{borderRadius:"5px"}}>
        <div className={classes.demo1}>
          <AntTabs value={value} onChange={handleChange} aria-label="ant example">
            <AntTab label="Money Transfer" />
            <AntTab label="Withdraw Requests" />
          </AntTabs>
        </div>
        {value===0?
        // Tab1
        <div style={{
          backgroundColor:"white",
          padding:"40px"
        }}>
          <p>Fill Information </p>
          <CssTextField label="Amount" margin="normal" 
            inputProps={{style: {fontSize: 16}}} // font size of input text
            InputLabelProps={{style: {
              fontSize: 16,
              width:"40px"
            }}}
            onChange={handleChangeWithDrawAmount}
            value={getWithDrawAmount}
            variant="outlined"
          />
          <br></br>
          <CssTextField 
              margin="normal" 
              inputProps={{style: {fontSize: 16}}} // font size of input text
              InputLabelProps={{style: {
                fontSize: 16,
              }}}
              onChange={handleChangepaytmNumber}
              value={getPAYTMNumber}
              variant="outlined"
              style={{maxWidth:"600px",width:"100%"}}
            />
          <br></br>
          <StyledButton1 variant="outlined" onClick={AddMoneyRequest}>add Amount</StyledButton1>
        </div>
        :        
        // Tab2
        <>
        <div style={{
          background:"white",
          display: "flex",
          flexWrap:"wrap",
          padding:"20px",
          justifyContent:"center"
        }}>
            <TextField
              id="datetime-local"
              label="From Date"
              type="datetime-local"
              onChange={handleChangeFromDate}
              value={getFromDate}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              id="datetime-local"
              label="To Date"
              type="datetime-local"
              onChange={handleChangeToDate}
              value={getToDate}
              InputLabelProps={{
                shrink: true,
              }}
            />
          <br></br>
          <StyledButton1 onClick={getWithDrawRequests} variant="outlined" >Get</StyledButton1>
        </div>
        <div style={{borderLeft:"5px solid grey",textAlign:"left",borderBottomLeftRadius:"inherit"}}>
        <table className="table table-striped customTable1" style={{marginBottom: "0px",}} >
          <tbody>
            {result2.length?result2:<h5 style={{margin:"3px"}}>No Withdrawal Requests [Try For different Dates]</h5>}
          </tbody>
        </table>
      </div> 
      </>
        }
        
      </div>
    </div>
  );
}