import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import "../CommonStyle.css"
import StyledButton1 from './buttons'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import axios from 'axios'
import CircularLoader from './CircularLoader'


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const GreenRadio = withStyles({
  root: {
    color: "white",
    '&$checked': {
      color: "Black",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const RedButtonBlockCSS={
  border:"1px solid black",
  width:"40px",
  height:"40px",
  backgroundColor:"red"
};
const GreenButtonBlockCSS={
  border:"1px solid black",
  width:"40px",
  height:"40px",
  backgroundColor:"green"
};




const GreenButtonBlockCSSType0={
  border:"1px solid black",
  width:"40px",
  height:"120px",
  backgroundColor:"#F07338",
  marginLeft:"5px"
};

const ButtonNumberCSS={
  marginTop:"-20px",
  marginLeft:"25px",
  color:"white"
};





export default function NumberGame(userData) {
  console.log(userData)

  const [getBidNumber, setBidNumber] = React.useState('0');
  const [getBidAmount, setBidAmount] = React.useState('10');
  const [getPopUpStage, setPopUpStage] = React.useState('preview-stage');
  const [getNote, setNote] = React.useState('');
  

  const handleChangeBidNumber = (event) => {
    setBidNumber(event.target.value);
  };

  const handleChangeBidAmount = (event) => {
    setBidAmount(event.target.value);
  };

  function SubmitGamePlayRequest (){
    setPopUpStage('loading-stage')
    var data = JSON.stringify(
    {
      "bidAmount":getBidAmount,
      "bidNumber":getBidNumber
    });

    var config = {
      method: 'post',
      url: 'https://luckynumber-v1.herokuapp.com/startGamePlay',
      headers: { 
        'idtoken': userData.userData.idToken, 
        'Content-Type': 'application/json'
      },
      data : data
    };

    axios(config)
    .then(function (response) {
      if(response.data.success){
        setNote(`Your Bid has been Placed on Number ${getBidNumber} of price ${getBidAmount}`)
        setPopUpStage('finish-stage')
      }
      else{
        setNote(response.data.message)
        setPopUpStage('finish-stage')
      }
    })
    .catch(function (error) {
      setPopUpStage('preview-stage')
    });
  }

 // PopUP
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function getGameTime() {
    var date=new Date()
    var hours = date.getHours();
    // var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    // minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = `${hours+1}:00${ampm} ${date.toLocaleDateString()}`
    return strTime;
}
function getGameResultTime() {
    var date=new Date()
    var hours = date.getHours();
    // var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    // minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = `${hours+1}:00${ampm}-${hours+1}:15${ampm}`
    return strTime;
}

  function PopUpSection(){
    var elements=<></>
    console.log(getPopUpStage)
    if(getPopUpStage==='preview-stage'){
    elements=<Dialog
    open={open}
    TransitionComponent={Transition}
    keepMounted
    onClose={handleClose}
    aria-labelledby="alert-dialog-slide-title"
    aria-describedby="alert-dialog-slide-description"
  >
    <DialogTitle id="alert-dialog-slide-title"><h2>You Need To accept, T&C to Start Game</h2></DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-slide-description">
        <h4>Your Bid Amount : {getBidAmount}</h4>
        <h4>Your Bid Number : {getBidNumber}</h4>
        <h4>Your Game Slot : {getGameTime()}</h4>
        <h4>Result Between : {getGameResultTime()}</h4>
        <br></br>
        After Accepting Terms & Conditions You can not Cancel the Game or No money Will be refunded Back.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <StyledButton1 onClick={handleClose} variant="outlined" >Go Back</StyledButton1>
      <StyledButton1 onClick={SubmitGamePlayRequest} variant="outlined" >Agree</StyledButton1>
    </DialogActions>
  </Dialog>
    }
    else if (getPopUpStage==='loading-stage'){
        elements=<Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title"></DialogTitle>
        <DialogContent>
          <CircularLoader/>
        </DialogContent>
        <DialogActions>
        </DialogActions>
      </Dialog>
    }
    else if (getPopUpStage==='finish-stage'){
      elements=<Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title"></DialogTitle>
      <DialogContent>
        
        <h3>{getNote}</h3>
      </DialogContent>
      <DialogActions>
        <StyledButton1 onClick={handleClose} variant="outlined" >Finish</StyledButton1>
      </DialogActions>
    </Dialog>
    }

    return elements
  }


  function RenderMoney(params) {  
    return (
      <div style={{display: "flex",margin:"10px",flexWrap:"wrap"}}>
          <div style={{
            width:"140px",
            height:"70px",
            margin:"20px"
          }}>
            <GreenRadio
              checked={getBidAmount === '10'}
              onChange={handleChangeBidAmount}
              value="10"
              name="radio-button-demo"
              inputProps={{ 'aria-label': '10' }}
            />
            <img style={{height:"inherit",width:"inherit",marginTop:"-50px"}}src="https://cdn.imgbin.com/9/0/10/imgbin-indian-10-rupee-note-indian-rupee-banknote-currency-indian-rupee-banknote-photos-10-indian-rupee-2jk-430477-banknote-RBxx3yayA3eniSeN04khj5UgN.jpg" alt="10 rupee note"></img>
          </div>
  
          <div style={{
            width:"140px",
            height:"70px",
            margin:"20px"
          }}>
            <GreenRadio
              checked={getBidAmount === '20'}
              onChange={handleChangeBidAmount}
              value="20"
              name="radio-button-demo"
              inputProps={{ 'aria-label': '20' }}
            />
            <img style={{height:"inherit",width:"inherit",marginTop:"-50px"}}src="https://upload.wikimedia.org/wikipedia/commons/5/53/India_new_20_INR%2C_MG_series%2C_2019%2C_obverse.jpg" alt="10 rupee note"></img>
          </div>
  
          <div style={{
            width:"140px",
            height:"70px",
            margin:"20px"
          }}>
            <GreenRadio
              checked={getBidAmount === '50'}
              onChange={handleChangeBidAmount}
              value="50"
              name="radio-button-demo"
              inputProps={{ 'aria-label': '50' }}
            />
            <img style={{height:"inherit",width:"inherit",marginTop:"-50px"}}src="https://upload.wikimedia.org/wikipedia/commons/3/3f/India_new_50_INR%2C_MG_series%2C_2018%2C_obverse.jpg" alt="10 rupee note"></img>
          </div>
  
          <div style={{
            width:"140px",
            height:"70px",
            margin:"20px"
          }}>
            <GreenRadio
              checked={getBidAmount === '100'}
              onChange={handleChangeBidAmount}
              value="100"
              name="radio-button-demo"
              inputProps={{ 'aria-label': '100' }}
            />
            <img style={{height:"inherit",width:"inherit",marginTop:"-50px"}}src="https://upload.wikimedia.org/wikipedia/commons/8/88/India_new_100_INR%2C_Mahatma_Gandhi_New_Series%2C_2018%2C_obverse.png" alt="10 rupee note"></img>
          </div>
  
          <div style={{
            width:"140px",
            height:"70px",
            margin:"20px"
          }}>
            <GreenRadio
              checked={getBidAmount === '200'}
              onChange={handleChangeBidAmount}
              value="200"
              name="radio-button-demo"
              inputProps={{ 'aria-label': '200' }}
            />
            <img style={{height:"inherit",width:"inherit",marginTop:"-50px"}}src="https://upload.wikimedia.org/wikipedia/commons/f/fa/India%2C_200_INR%2C_2018%2C_obverse.jpg" alt="200 rupee note"></img>
          </div>
  
          <div style={{
            width:"140px",
            height:"70px",
            margin:"20px"
          }}>
            <GreenRadio
              checked={getBidAmount === '500'}
              onChange={handleChangeBidAmount}
              value="500"
              name="radio-button-demo"
              inputProps={{ 'aria-label': '500' }}
            />
            <img style={{height:"inherit",width:"inherit",marginTop:"-50px"}}src="https://upload.wikimedia.org/wikipedia/commons/2/2e/India_new_500_INR%2C_MG_series%2C_2016%2C_obverse.jpg" alt="500 rupee note"></img>
          </div>
  
          <div style={{
            width:"140px",
            height:"55px",
            margin:"20px"
          }}>
            <GreenRadio
              checked={getBidAmount === '1000'}
              onChange={handleChangeBidAmount}
              value="1000"
              name="radio-button-demo"
              inputProps={{ 'aria-label': '1000' }}
            />
            <img style={{height:"inherit",width:"inherit",marginTop:"-60px",objectFit:"cover"}} src="https://i.ytimg.com/vi/1GZpuglFH_M/maxresdefault.jpg" alt="1000 rupee note"></img>
          </div>
      </div>
    );
  }

  function RenderNumbers(params) {
    
    return (
      <div style={{display: "flex",margin:"10px"}}>
      <div style={{flexDirection: "row"}}>
        <div style={GreenButtonBlockCSSType0}>
          <GreenRadio
            checked={getBidNumber === '0'}
            onChange={handleChangeBidNumber}
            value="0"
            name="radio-button-demo"
            inputProps={{ 'aria-label': '0' }}
          />
          <p style={ButtonNumberCSS}>0</p>
        </div>
      </div>
    
      <div style={{flexDirection: "column"}}>
      <div style={{display: "flex"}}>
      <div style={RedButtonBlockCSS}>
        <GreenRadio
          checked={getBidNumber === '1'}
          onChange={handleChangeBidNumber}
          value="1"
          name="radio-button-demo"
          inputProps={{ 'aria-label': '1' }}
        />
        <p style={ButtonNumberCSS}>1</p>
      </div>
      <div style={GreenButtonBlockCSS}>
        <GreenRadio
          checked={getBidNumber === '2'}
          onChange={handleChangeBidNumber}
          value="2"
          name="radio-button-demo"
          inputProps={{ 'aria-label': '2' }}
        />
        <p style={ButtonNumberCSS}>2</p>
      </div>
      <div style={RedButtonBlockCSS}>
        <GreenRadio
          checked={getBidNumber === '3'}
          onChange={handleChangeBidNumber}
          value="3"
          name="radio-button-demo"
          inputProps={{ 'aria-label': '3' }}
        />
        <p style={ButtonNumberCSS}>3</p>
      </div>
      </div>
      <div style={{display: "flex"}}>
      <div style={GreenButtonBlockCSS}>
        <GreenRadio
          checked={getBidNumber === '4'}
          onChange={handleChangeBidNumber}
          value="4"
          name="radio-button-demo"
          inputProps={{ 'aria-label': '4' }}
        />
        <p style={ButtonNumberCSS}>4</p>
      </div>
      <div style={RedButtonBlockCSS}>
        <GreenRadio
          checked={getBidNumber === '5'}
          onChange={handleChangeBidNumber}
          value="5"
          name="radio-button-demo"
          inputProps={{ 'aria-label': '5' }}
        />
        <p style={ButtonNumberCSS}>5</p>
      </div>
      <div style={GreenButtonBlockCSS}>
        <GreenRadio
          checked={getBidNumber === '6'}
          onChange={handleChangeBidNumber}
          value="6"
          name="radio-button-demo"
          inputProps={{ 'aria-label': '6' }}
        />
        <p style={ButtonNumberCSS}>6</p>
      </div>
      </div>
      <div style={{display: "flex"}}>
      <div style={RedButtonBlockCSS}>
        <GreenRadio
          checked={getBidNumber === '7'}
          onChange={handleChangeBidNumber}
          value="7"
          name="radio-button-demo"
          inputProps={{ 'aria-label': '7' }}
        />
        <p style={ButtonNumberCSS}>7</p>
      </div>
      <div style={GreenButtonBlockCSS}>
        <GreenRadio
          checked={getBidNumber === '8'}
          onChange={handleChangeBidNumber}
          value="8"
          name="radio-button-demo"
          inputProps={{ 'aria-label': '8' }}
        />
        <p style={ButtonNumberCSS}>8</p>
      </div>
      <div style={RedButtonBlockCSS}>
        <GreenRadio
          checked={getBidNumber === '9'}
          onChange={handleChangeBidNumber}
          value="9"
          name="radio-button-demo"
          inputProps={{ 'aria-label': '9' }}
        />
        <p style={ButtonNumberCSS}>9</p>
      </div>
    </div>
    </div>
    </div>
    );
  }
  
  return (
    <div style={{margin:"20px"}}>
      <h3>1. Select Your Bid Price</h3>
        <RenderMoney/>
      <h3>2. Select Bid Number</h3>
        <RenderNumbers/>
      <div style={{margin:"40px 0px 0px 15px"}}>
        <StyledButton1 onClick={handleClickOpen} variant="outlined" >Submit Bid</StyledButton1>
      </div>  
        <PopUpSection/>
    </div>

  );
}