import React, { Component, Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import firebase from "../../firebase";
import "./HeaderStyles.css";


import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import StyledButton from '../../Components/buttons'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  headerCustom:{
    backgroundColor: "#FFFFFF",
    color:"#000000",
    borderTop:"2px solid #F07338",
    boxShadow: "none",
    borderBottom: "1px solid #DFDFDF",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

function SignOut (){
  firebase
    .auth()
    .signOut()
    .then(_ => {
      toast.success("successfully user signout");
      this.props.history.push("/login");
    })
    .catch(err => toast.error(err.message));
};

function HeaderComponent(para){
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
  
    const handleMenu = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    return (
      <div className={classes.root}>
        <AppBar className={classes.headerCustom} position="fixed">
          <Toolbar>
          <Link to="/"><IconButton edge="start" className={classes.menuButton} to='/' color="inherit" aria-label="menu">
              <img src={process.env.PUBLIC_URL + '/favicon.png'} style={{height:"30px",width:"30px"}} alt=""/>   
            </IconButton></Link>
            <Typography variant="h4" className={classes.title}>
              Ek Ka 6
            </Typography>
            {para.userData? (
              <div>
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={open}
                  onClose={handleClose}
                >
                  <MenuItem>ID : {para.userData.phoneNumber}</MenuItem>
                  <Link to="/profile"> <MenuItem>Profile</MenuItem></Link>
                  <Link to="/myAccount"> <MenuItem>my Account</MenuItem></Link>
                  <MenuItem onClick={SignOut}>Logout</MenuItem>
                </Menu>
              </div>
            ):<div>
              <StyledButton href="/login" >Login</StyledButton>
              </div>}
          </Toolbar>
        </AppBar>
      </div>
    );
}

export default withRouter(HeaderComponent);
