import React, { Component, Fragment, useState } from "react";
import firebase from "../firebase";
import axios from 'axios';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {Tabs,Tab} from '@material-ui/core';
import { makeStyles,withStyles } from '@material-ui/core/styles';
import { ThreeSixtySharp } from "@material-ui/icons";
import CustomizedTabs from  './MoneyAdminController'
import LinearLoader from '../Components/LinearLoader'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import StyledButton1 from '../Components/buttons'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';


const selectStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const popUpStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  labelRoot: {
    fontSize: 18
  },
  labelFocused: {}
}));

const StyledButton = withStyles({
  root: {
    background:"#F07338",
    borderRadius: 3,
    border: 0,
    color:"white",
    height: 40,
    padding: '0 30px',
    fontSize:'14px'
  },
  label: {
    textTransform: 'capitalize',
  },
})(Button);

const CssTextField = withStyles({
  root: {
    width:"100%",
    '& label.Mui-focused': {
      color: '#F07338',
      fontSize: 18,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#F07338',
      fontSize: 16,
    },
    '& .MuiOutlinedInput-root': {
      fontSize: 18,
      '& fieldset': {
        borderColor: '#000',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#F07338',
      },
    },
  },
})(TextField);

class AdminMain extends Component {
  constructor(props) {
    super(props);
    this.state = this.props
  }


  //handle Change event
  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  //handle Change event
  handleChangeGameResult = e => {
    console.log(e.target.name)
    let newGamePendingResult =this.state.GamePendingResult.map(game=>{
      if(game.gameId===e.target.name){
        game.winnerNumber= e.target.value
      }
      return game
    })
    this.setState({
      GamePendingResult:newGamePendingResult
    })
  };

  declareGameResult=(gameId)=>{
    var data = JSON.stringify({
      "gameId":gameId,
      "winnerNumber":(this.state.GamePendingResult.find(game=>game.gameId===gameId)).winnerNumber
    });
    var config = {
      method: 'post',
      url: 'https://luckynumber-v1.herokuapp.com/setGameResult',
      headers: { 
        'idtoken': this.state.userData.idToken,
        'Content-Type': 'application/json'
      },
      data : data
    };

    axios(config)
    .then(function (response) {
      toast(response.data.message)
    })
    .catch(function (error) {
      toast("Something Went Wrong!")
    });
  }

  showEntries=(bol)=>{
    this.setState({ openEntiresDialogBox: bol });
  }

  setPreview=(gameId)=>{
    this.showEntries(true)
    this.setState({ previewGameId: gameId });
  }

  GetGamePlayerTable=()=>{
    let gameId=this.state.previewGameId||""
    if(!gameId) return <></>
    let classes=selectStyles()
    let GamePendingResult=this.state.GamePendingResult||[]
    let gameObj=GamePendingResult.find(item=>item.gameId===gameId)
    gameObj=gameObj||{}
    let players=gameObj.players||[]
    let result1=players.map(item=>{
      return <>
        <tr key={item.userId}>
          <td>{item.timeStamp}</td>
          <td>{item.phoneNumber}</td>
          <td>Number : {item.bidNumber}</td>
          <td>{item.bidAmount}₹</td>
        </tr>
      </>
    })

    return (
      <div className="customCard1" style={{marginTop:"85px"}}>
          <div style={{borderLeft:"5px solid Red",borderTopLeftRadius:"inherit",padding:"10px",textAlign:"center",borderBottom:"1px solid #DFDFDF"}}>
            <h2 style={{color:"#333333"}}>
            Player Entries in {gameId}
            </h2>
          </div>
          <div style={{borderLeft:"5px solid grey",textAlign:"left",borderBottomLeftRadius:"inherit"}}>
            <table className="table table-striped customTable1" style={{marginBottom: "0px",textAlign:"center"}} >
              <tbody>
                <tr>
                  <th>TimeStamp</th>
                  <th>PhoneNumber</th>
                  <th>bidNumber</th>
                  <th>bidAmount</th>
                </tr>
                {result1}
              </tbody>
            </table>
          </div> 
        </div>
    );
  }

  GameResults=()=>{
    let classes=selectStyles()
    if(!this.state.GamePendingResult) return <LinearLoader/>
    function sort_by_key(array, key)
    {
      return array.sort(function(a, b)
      {
        var x = a[key]; var y = b[key];
        return ((x > y) ? -1 : ((x < y) ? 1 : 0));
      });
    }
    let GamePendingResult=sort_by_key(this.state.GamePendingResult,'resultStatus')
    let result1=GamePendingResult.map(item=>{
      return <>
        <tr key={item.gameId}>
        <td>{item.gameId}</td>
        {item.resultStatus==="PENDING"?
        <>
        <td> <h4 style={{color:"RED"}}>{item.resultStatus}</h4><h4 style={{color:"#F07338",border:"1px solid",borderRadius:"5px"}} onClick={()=>{this.setPreview(item.gameId)}}><a>Show Entries</a></h4></td>
        <td>
        <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-filled-label">Result</InputLabel>
        <Select
          labelId="demo-simple-select-filled-label"
          name={item.gameId}
          value={item.winnerNumber||""}
          onChange={this.handleChangeGameResult}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={0}>Zero</MenuItem>
          <MenuItem value={1}>One</MenuItem>
          <MenuItem value={2}>Two</MenuItem>
          <MenuItem value={3}>Three</MenuItem>
          <MenuItem value={4}>Four</MenuItem>
          <MenuItem value={5}>Five</MenuItem>
          <MenuItem value={6}>Six</MenuItem>
          <MenuItem value={7}>Seven</MenuItem>
          <MenuItem value={8}>Eight</MenuItem>
          <MenuItem value={9}>Nine</MenuItem>
        </Select>
        </FormControl>
        </td>
        <td><StyledButton1 variant="outlined"  onClick={() => this.declareGameResult(item.gameId)}>Declare Result</StyledButton1></td></>:
        <>
        <td style={{color:"GREEN"}}>{item.resultStatus}</td>
        <td style={{color:"GREEN"}}>Number : {item.winnerNumber}</td>
        <td style={{color:"RED"}}>Can Not be Changed!</td>
        </>
        }
        </tr>
      </>
      
    })

    return (
      <div className="customCard1">
          <div style={{borderLeft:"5px solid Red",borderTopLeftRadius:"inherit",padding:"10px",textAlign:"center",borderBottom:"1px solid #DFDFDF"}}>
            <h2 style={{color:"#333333"}}>
            Game Results
            </h2>
          </div>
          <div style={{borderLeft:"5px solid grey",textAlign:"left",borderBottomLeftRadius:"inherit"}}>
            <table className="table table-striped customTable1" style={{marginBottom: "0px",textAlign:"center"}} >
              <tbody>
                {result1}
              </tbody>
            </table>
          </div> 
        </div>
    );
  }


  componentDidMount() {
    firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        let walletBalance=0
        let userObj=await user.getIdTokenResult()
        let walletUrl='users/'+user.uid+'/wallet'
        console.log(user)
        walletBalance=await new Promise(resolve=>{
          let ref=firebase.database().ref(walletUrl)
          ref.on('value',(snapshot)=>{
            if(snapshot.val()!=null){
              resolve(snapshot.val().balance||0)
            }
            else resolve(0)
          })
        })
        console.log(2)
        let userObject={
          admin:userObj.claims.admin|| false,
          phoneNumber:user.phoneNumber,
          displayName:user.displayName||"",
          photoURL:user.photoURL||"",
          userId:user.uid,
          idToken:userObj.token,
          walletBalance:walletBalance
        }

        var config = {
          method: 'post',
          url: 'https://luckynumber-v1.herokuapp.com/getAllGamePlays',
          headers: { 
            'idtoken': userObj.token,
            'Content-Type': 'application/json'
          }
        };
        var GamePendingResult=[]
        try{
          let response=await axios(config)
          GamePendingResult=response.data
        }
        catch(err){
          console.log(err)
        }
        var newState={ 
          userData: userObject,
          loggedIn:true,
          GamePendingResult:GamePendingResult
        }
        this.setState(newState);
        // this.props.history.push("/list-movies");
      } else {
        this.setState({ userData: "" ,loggedIn:false});
        //this.props.history.push("/login");
      }
    });
  }

  getGamePlayersTable=()=>{

  }

  render() {
    console.log(this.state)
    return (
      <Fragment>
        <CustomizedTabs userData={this.state.userData} />
        <this.GameResults/>
          <div>
            <Dialog fullScreen open={this.state.openEntiresDialogBox} onClose={()=>{this.showEntries(true)}} TransitionComponent={Transition}>
              <AppBar  style={{ background: '#f0956c' }}>
                <Toolbar>
                  <IconButton edge="start" color="inherit" onClick={()=>{this.showEntries(false)}} aria-label="close">
                    <CloseIcon />
                  </IconButton>
                  <h3>{this.state.previewGameId||""}</h3>
                </Toolbar>
              </AppBar>
              <this.GetGamePlayerTable />
            </Dialog>
          </div>
      </Fragment>
    );
  }
}

export default AdminMain;
