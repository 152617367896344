import React from 'react';
import { makeStyles, withStyles,createMuiTheme } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TextField from '@material-ui/core/TextField';
import { red } from '@material-ui/core/colors';
import StyledButton1 from '../Components/buttons'
import { Link } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const CssTextField = withStyles({
  root: {
    marginTop:'0px',
    '& label.Mui-focused': {
      color: '#F07338',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#4D4D4D',
      },
      '& fieldset legend': {
        width:'60px'
      },
      '&:hover fieldset': {
        borderColor: '#000000',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#F07338',
      },
    }
  },
})(TextField);

const AntTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8'
  },
  indicator: {
    backgroundColor: '#F07338',
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontSize:16,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    '&:hover': {
      color: '#00000',
      opacity: 1,
    },
    '&$selected': {
      color: '#F07338',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#00000',
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  padding: {
    padding: theme.spacing(3),
  },
  demo1: {
    backgroundColor: theme.palette.background.paper,
  },
  demo2: {
    backgroundColor: '#2e1534',
  },
}));



export default function CustomizedTabs(userData) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [getWithDrawAmount, setWithDrawAmount] = React.useState(100);
  const [getAccountNumber, setAccountNumber] = React.useState("");
  const [getIFSC, setIFSC] = React.useState("");
  const [getWithdrawType, setWithdrawType] = React.useState("PAYTM");
  const [getPAYTMNumber, setPAYTMNumber] = React.useState("+91");
  const [getupiId, setupiId] = React.useState("");
  const [getNote, setNote] = React.useState("");

  const handleChangeWithDrawAmount = (event) => {
    setWithDrawAmount(event.target.value);
  };

  const handleChangepaytmNumber = (event) => {
    setPAYTMNumber(event.target.value);
  };

  const handleChangeUpiId = (event) => {
    setupiId(event.target.value);
  };

  const handleChangeAccountNumber = (event) => {
    setAccountNumber(event.target.value);
  };

  const handleChangeIFSCCode = (event) => {
    setIFSC(event.target.value);
  };

  const handleChangeWithdrawType=(event)=>{
    setWithdrawType(event.target.value);
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function submitWithDrawRequest() {
    let idToken=userData.userData.idToken
    if(getWithdrawType==='PAYTM'){
      if(getPAYTMNumber.toString().length!=13||!getPAYTMNumber.toString().includes('+91')){
        toast.error('🚀 Phone Number is Invalid!', {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
          return 
      }
      var data = JSON.stringify(
        {
          "withdrawType":getWithdrawType,
          "amount":Number(getWithDrawAmount),
          "phoneNumber":getPAYTMNumber}
      );
    }
    else if(getWithdrawType==='UPI'){
      if(!getupiId.toString().includes('@')){
        toast.error('🚀 UPI ID is Invalid!', {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
          return 
      }
      var data = JSON.stringify(
        {
          "withdrawType":getWithdrawType,
          "amount":Number(getWithDrawAmount),
          "upiId":getupiId}
      );
    }
    else if(getWithdrawType==='BANK'){
      if(getAccountNumber.toString().length<10){
        toast.error('🚀 Account Number is Invalid!', {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
          return 
      }
      var data = JSON.stringify(
        {
          "withdrawType":getWithdrawType,
          "amount":Number(getWithDrawAmount),
          "accountNumber":getAccountNumber,
          "ifscCode":getIFSC
        }
      );
    }

    var config = {
      method: 'post',
      url: 'https://luckynumber-v1.herokuapp.com/submitWithdrawRequest',
      headers: { 
        'idtoken': idToken, 
        'Content-Type': 'application/json'
      },
      data : data
    };

    axios(config)
    .then(function (response) {
      toast(response.data.message,{
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    })
    .catch(function (error) {
      console.log(error);
    });

  }

  var card=<></>
  if(getWithdrawType==='PAYTM'){
      card=<CssTextField label="PAYTM Number" margin="normal" 
      inputProps={{style: {fontSize: 16}}} // font size of input text
      InputLabelProps={{style: {
        fontSize: 16
      }}}
      onChange={handleChangepaytmNumber}
      value={getPAYTMNumber}
      variant="outlined"
      style={{maxWidth:"600px",width:"100%"}}
    />;
  }
  else if (getWithdrawType=='UPI'){
    card=<CssTextField label="UPI ID" margin="normal" 
    inputProps={{style: {fontSize: 16}}} // font size of input text
    InputLabelProps={{style: {
      fontSize: 16
    }}}
    onChange={handleChangeUpiId}
    value={getupiId}
    variant="outlined"
    style={{maxWidth:"600px",width:"100%"}}
    />
  }
  else if(getWithdrawType=='BANK'){
      var card=<><CssTextField label="Account Number" margin="normal" 
        inputProps={{style: {fontSize: 16}}} // font size of input text
        InputLabelProps={{style: {
          fontSize: 16
        }}}
        onChange={handleChangeAccountNumber}
        value={getAccountNumber}
        variant="outlined"
        style={{maxWidth:"600px",width:"100%"}}
      />
      <br></br>
      <CssTextField label="IFSC Code" margin="normal" 
        inputProps={{style: {fontSize: 16,width:"80%"}}} // font size of input text
        InputLabelProps={{style: {
          fontSize: 16,
        }}}
        onChange={handleChangeIFSCCode}
        value={getIFSC}
        variant="outlined"
      /><Link href="https://bankifsccode.com/" target='_blank' style={{marginTop:"20px",fontSize:"12px"}}>Get IFSC </Link>
      </>
  }

  return (
    <div className="customCard1" style={{marginTop:"40px",backgroundColor:"#fff4f0"}}>
      <div className={classes.root} style={{borderRadius:"5px"}}>
        <div className={classes.demo1}>
          <AntTabs value={value} onChange={handleChange} aria-label="ant example">
            <AntTab label="Add Money +" />
            <AntTab label="Withdraw Money" />
          </AntTabs>
        </div>
        {value===0?
        // Tab1
        <div style={{
          backgroundColor:"white",
          padding:"40px"
        }}>
          {/* <p>Enter Amount</p>
          <CssTextField label="Amount" margin="normal" 
            inputProps={{style: {fontSize: 16}}} // font size of input text
            InputLabelProps={{style: {
              fontSize: 16,
              width:"40px"
            }}}
            variant="outlined"
          />
          <br></br>
          <StyledButton1 variant="outlined" >add Amount</StyledButton1> */}
          <h4 style={{color:"red"}}>Note : To Add Money in Wallet, Just PayTm Money to +91 9987654321 from Your Registered Number Only </h4>
          <h5 style={{color:"grey"}}>It May Take 5-10 Minutes to Reflect in your wallet</h5>
        </div>
        :        
        // Tab2
        <div style={{
          backgroundColor:"white",
          padding:"40px"
        }}>
          <CssTextField label="Amount" margin="normal" 
            inputProps={{style: {fontSize: 16}}} // font size of input text
            InputLabelProps={{style: {
              fontSize: 16,
              width:"40px"
            }}}
            onChange={handleChangeWithDrawAmount}
            value={getWithDrawAmount}
            variant="outlined"
          />
          <br></br>
          <FormControl variant="outlined" style={{marginBottom:"5px"}} className={classes.formControl}>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={getWithdrawType}
              onChange={handleChangeWithdrawType}
            >
              {/* <MenuItem value={'BANK'}>BANK</MenuItem> */}
              <MenuItem value={'PAYTM'}>PAYTM</MenuItem>
              <MenuItem value={'UPI'}>UPI</MenuItem>
            </Select>
          </FormControl>
          <br></br>
          {card}
          <br></br>
          <StyledButton1 onClick={submitWithDrawRequest} variant="outlined" >Withdraw Money</StyledButton1>
        </div>
        
        }
        
      </div>
    </div>
  );
}