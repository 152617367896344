import React, { Component, Fragment, useState } from "react";
import { toast } from "react-toastify";
import firebase from "../../firebase";
import "../AuthComponent/ProfilePage";
import axios from 'axios';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {Tabs,Tab} from '@material-ui/core';
import { makeStyles,withStyles } from '@material-ui/core/styles';
import { ThreeSixtySharp } from "@material-ui/icons";
import CustomizedTabs from  '../../Components/TabsComponent'
import LinearLoader from '../../Components/LinearLoader'
import MyGamesHistory from './MyGamesHistory'
import walletIcon from '../../assets/walletIcon.svg'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  labelRoot: {
    fontSize: 18
  },
  labelFocused: {}
}));

const StyledButton = withStyles({
  root: {
    background:"#F07338",
    borderRadius: 3,
    border: 0,
    color:"white",
    height: 40,
    padding: '0 30px',
    fontSize:'14px'
  },
  label: {
    textTransform: 'capitalize',
  },
})(Button);

const CssTextField = withStyles({
  root: {
    width:"100%",
    '& label.Mui-focused': {
      color: '#F07338',
      fontSize: 18,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#F07338',
      fontSize: 16,
    },
    '& .MuiOutlinedInput-root': {
      fontSize: 18,
      '& fieldset': {
        borderColor: '#000',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#F07338',
      },
    },
  },
})(TextField);

class MoneyComponent extends Component {
  constructor(props) {
    super(props);
    this.state = this.props
  }

  //handle Change event
  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = async e => {
    let { email, password } = this.state;
    let { history, match } = this.props;
    try {
      e.preventDefault();
      let userInfo = await firebase
        .auth()
        .signInWithEmailAndPassword(email, password);
      if (userInfo.user.emailVerified) {
        console.log(userInfo);
        history.push("/"); //not refreshing
        // window.location.assign("/"); //problem is refreshing page
        toast.success("successfully logged in");
      } else {
        let errorMessage = `${email} is not Yet verified Please first verify email address then login`;
        toast.error(errorMessage);
      }
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    }
  };

  MyTransactionComponent=()=>{
    if(!this.state.transactionHistory) return <LinearLoader/>
    function custom_sort(a, b) {
      return new Date(b.lastUpdated).getTime()-new Date(a.lastUpdated).getTime(); 
    }
    let transactionHistory=this.state.transactionHistory.sort(custom_sort);
    let result2=transactionHistory.map(item=>{
      return <>
        <tr key={item.transactionId}>
        <td>{new Date(item.timeStamp).toString().split('GMT')[0]}</td>
        <td>{item.transactionType}</td>
        {item.transactionType.includes('debit')?
          <td style={{color:"red"}}>{item.amount}₹</td>:
          <td style={{color:"green"}}>{item.amount}₹</td>
        }
        </tr>
      </>
    })

    return (
      <div className="customCard1">
          <div style={{borderLeft:"5px solid Red",borderTopLeftRadius:"inherit",padding:"10px",textAlign:"center",borderBottom:"1px solid #DFDFDF"}}>
          <h3 style={{color:"#333333",margin:"0px"}}>
            Transactions
            </h3>
          </div>
          <div style={{borderLeft:"5px solid grey",textAlign:"left",borderBottomLeftRadius:"inherit"}}>
            <table className="table table-striped" style={{marginBottom: "0px"}} >
              <tbody>
                {result2}
              </tbody>
            </table>
          </div> 
        </div>
    );
  }


  
  myWallet=()=>{
    console.log(this.state.userData)
    return (
      <div className="customCard1" style={{marginTop:"100px",backgroundColor:"#fff4f0"}}>
        <div style={{display:"flex",flexWrap:"wrap"}}>
          <div style={{margin:"auto",padding:"15px",width:"min-content"}} >
              <img src={walletIcon} style={{height:"200px",width:"200px",padding:"20px"}} alt=""/>   
          </div>
          <div style={{margin:"auto",padding:"0px 10px 0px 0px"}}>
            <h2><span style={{color:"#F07338"}}>Wallet Balance </span>₹{this.state.userData.walletBalance||0}</h2>
          </div>
          {/* <StyledButton style={{marginLeft:"8px",marginTop:"40px"}} onClick={this.submitOTP} >Save</StyledButton> */}
        </div>
      </div>

    );
  }

  componentDidMount() {
    firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        let walletBalance=0
        let userObj=await user.getIdTokenResult()
        let walletUrl='users/'+user.uid+'/wallet'
        console.log(user)
        walletBalance=await new Promise(resolve=>{
          let ref=firebase.database().ref(walletUrl)
          ref.on('value',(snapshot)=>{
            if(snapshot.val()!=null){
              resolve(snapshot.val().balance||0)
            }
            else resolve(0)
          })
        })
        console.log(2)
        let userObject={
          admin:userObj.claims.admin|| false,
          phoneNumber:user.phoneNumber,
          displayName:user.displayName||"",
          photoURL:user.photoURL||"",
          userId:user.uid,
          idToken:userObj.token,
          walletBalance:walletBalance
        }

        var config = {
          method: 'post',
          url: 'https://luckynumber-v1.herokuapp.com/getMyTransactions',
          headers: { 
            'idtoken': userObj.token,
            'Content-Type': 'application/json'
          }
        };
        var transactionHistory=[]
        try{
          let response=await axios(config)
          if(!response.data.success) throw "Transaction request Failed!"
          transactionHistory=response.data.transactionHistory
        }
        catch(err){
          console.log(err)
        }
        var newState={ 
          userData: userObject,
          loggedIn:true,
          transactionHistory:transactionHistory
        }
        console.log(newState)
        this.setState(newState);
        // this.props.history.push("/list-movies");
      } else {
        this.setState({ userData: "" ,loggedIn:false});
        //this.props.history.push("/login");
      }
    });
  }

  render() {
    console.log(1)
    return (
      <Fragment>
        <this.myWallet/>
        <CustomizedTabs userData={this.state.userData} />
        <MyGamesHistory userData={this.state.userData} />
        <this.MyTransactionComponent/>
      </Fragment>
    );
  }
}

export default MoneyComponent;
