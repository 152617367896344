import React, { Component, Fragment, useState } from "react";
import { Link, withRouter, Redirect } from "react-router-dom";
import firebase from "../../firebase";
import { toast } from "react-toastify";


import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { makeStyles,withStyles } from '@material-ui/core/styles';
import loginImage from "../../assets/loginScreenImage.svg"


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  labelRoot: {
    fontSize: 18
  },
  labelFocused: {}
}));

const StyledButton = withStyles({
  root: {
    background:"#F07338",
    borderRadius: 3,
    border: 0,
    color:"white",
    height: 40,
    padding: '0 30px',
    fontSize:'14px'
  },
  label: {
    textTransform: 'capitalize',
  },
})(Button);

const CssTextField = withStyles({
  root: {
    width:"100%",
    '& label.Mui-focused': {
      color: '#F07338',
      fontSize: 18,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#F07338',
      fontSize: 16,
    },
    '& .MuiOutlinedInput-root': {
      fontSize: 18,
      '& fieldset': {
        borderColor: '#000',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#F07338',
      },
    },
  },
})(TextField);


function PhoneAuth(){
  const classes = useStyles();
  const [getPhoneNumber, setPhoneNumber] = React.useState('+91');
  const [getConfirmValue, setConfirmValue] = React.useState('');
  const [confirmationResult, setConfirmationResult] = React.useState('');
  const [otpSent, setOTPSent] = React.useState(false);


  const handlePhoneNumber = e => {
    setPhoneNumber(e.target.value);
  };
  const handleConfirmValue = e => {
    setConfirmValue(e.target.value);
  };
  const handleOTPSent = e => {
    setOTPSent(e.target.value);
  };

  const SubmitOTP = () => {
    confirmationResult
        .confirm(getConfirmValue)
        .then(user => {
          window.location.replace('/')
        })
        .catch(error => {
          toast("Error :", error);
        });
  }
  const SendOTP = () => {
    try {
      let captchaVerifier = new firebase.auth.RecaptchaVerifier(
        "recaptcha-container",
      );
      firebase
        .auth()
        .signInWithPhoneNumber(getPhoneNumber, captchaVerifier)
        .then(confirmationResult => {
          setOTPSent(true);
          setConfirmationResult(confirmationResult);
        })
        .catch(err => console.log(err));
    } catch (err) {
      console.log(err);
      toast.error(err.messages);
    }
  };


  return (
    <Fragment>
      <div  style={{display:"flex",flexWrap:"wrap",justifyContent:"center",margin:"150px 0px"}}>
        <div >
          <img src={loginImage} style={{width:"70%",margin:"30px",maxWidth:"400px"}} alt=""/>   
        </div>
        <div>
          <h2 style={{color:"#000",marginLeft:"10px",marginBottom:"30px"}}>Log in to Game </h2>
          <form className={classes.root} noValidate autoComplete="off">
            {otpSent?<><CssTextField
              className={classes.margin}
              label="Enter OTP"
              variant="outlined"
              InputLabelProps={{
                classes: {
                  root: classes.labelRoot,
                  focused: classes.labelFocused
                }
              }}
              name="confirmValue"
              id="custom-css-outlined-input"
              value={getConfirmValue}
              onChange={handleConfirmValue}
            />
            <StyledButton style={{marginLeft:"8px",marginTop:"40px"}} onClick={SubmitOTP} >Login</StyledButton></>:<>
            <CssTextField
              className={classes.margin}
              label="Phone Number"
              variant="outlined"
              InputLabelProps={{
                classes: {
                  root: classes.labelRoot,
                  focused: classes.labelFocused
                }
              }}
              name="phone"
              id="custom-css-outlined-input"
              value={getPhoneNumber}
              onChange={handlePhoneNumber}
            />
            <div id="recaptcha-container"></div>
            <StyledButton style={{marginLeft:"8px",marginTop:"40px"}} onClick={SendOTP} >Send OTP</StyledButton></>}
            
          </form>
        </div>

      </div>
    </Fragment>
  );
}

export default withRouter(PhoneAuth);
