import React from 'react';
import axios from 'axios' 
import LinearLoader from '../../Components/LinearLoader'

export default function MyGamesHistory(userData) {
    userData=userData.userData
    const [getMyGames, setMyGames] = React.useState([]);
    const [getCount, setCount] = React.useState(0);

    function getMyGameHistory (){
        var config = {
            method: 'post',
            url: 'https://luckynumber-v1.herokuapp.com/getMyGamePlay',
            headers: { 
              'idToken': userData.idToken
            }
          };
          
          axios(config)
          .then(function (response) {
            setMyGames(response.data);
            let k=getCount+1
            setCount(k)
          })
          .catch(function (error) {
            console.log(error);
          });          
    }
    if(getCount<3){
      getMyGameHistory()
    }
    if(!getMyGames.length) return <></>
    let result2=getMyGames.map(item=>{
      return <>
        
        <tr key={item.gameId}>
        <td>{item.gameId}</td>
        <td><h4>{item.bidNumber}</h4><h4>{item.winnerNumber?(item.bidNumber==item.winnerNumber?<span style={{color:"green"}}>Win</span>:<span style={{color:"red"}}>Lost</span>):""}</h4></td>
        <td>{item.bidAmount}₹</td>
        {item.resultStatus==='PENDING'?
          <td style={{color:"red"}}>{item.resultStatus}</td>:
          <td style={{color:"green"}}>{item.resultStatus}</td>
        }
        <td>{item.winnerNumber||"Pending..."}</td>
        </tr>
      </>
      
    })

    return (
      <div className="customCard1">
          <div style={{borderLeft:"5px solid Red",borderTopLeftRadius:"inherit",padding:"10px",textAlign:"center",borderBottom:"1px solid #DFDFDF"}}>
          <h3 style={{color:"#333333",margin:"0px"}}>
            Your Game Play History
            </h3>
          </div>
          <div style={{borderLeft:"5px solid grey",textAlign:"left",borderBottomLeftRadius:"inherit"}}>
            <table className="table table-striped customTable1" style={{marginBottom: "0px",textAlign:"center"}} >
              <tbody>
                <tr>
                  <th style={{textAlign:"center"}}>Game Id</th>
                  <th style={{textAlign:"center"}}>bidNumber</th>
                  <th style={{textAlign:"center"}}>bidAmount</th>
                  <th style={{textAlign:"center"}}>resultStatus</th>
                  <th style={{textAlign:"center"}}>winnerNumber</th>
                </tr>
                {result2}
              </tbody>
            </table>
          </div> 
        </div>
    );
}
