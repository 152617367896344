import React, { Component, Fragment } from "react";
import firebase from "../../firebase";
import "./Auth-Styles.css";


import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { makeStyles,withStyles } from '@material-ui/core/styles';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import profilePageIcon from '../../assets/profilePageIcon.svg'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  labelRoot: {
    fontSize: 18
  },
  labelFocused: {}
}));

const StyledButton = withStyles({
  root: {
    background:"#F07338",
    borderRadius: 3,
    border: 0,
    color:"white",
    height: 40,
    padding: '0 30px',
    fontSize:'14px'
  },
  label: {
    textTransform: 'capitalize',
  },
})(Button);

const CssTextField = withStyles({
  root: {
    width:"100%",
    '& label.Mui-focused': {
      color: '#F07338',
      fontSize: 18,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#F07338',
      fontSize: 16,
    },
    '& .MuiOutlinedInput-root': {
      fontSize: 18,
      '& fieldset': {
        borderColor: '#000',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#F07338',
      },
    },
  },
})(TextField);

function ProfilePage(para){
  let user=para.userData
  const classes = useStyles();
  const [displayName, setDisplayName] = React.useState({...user.displayName});
  const [email, setEmail] = React.useState('');

  React.useEffect(() => {
    setDisplayName(user.displayName);
  }, [user.displayName])

  //handle Change event
  const handleDisplayNameChange = e => {
    setDisplayName(e.target.value)
  };
  const handleEmailChange = e => {
    setEmail(e.target.value)
  };

  const SaveData=()=>{
    firebase.auth().onAuthStateChanged((user)=>{
      if (user) {
        user.updateProfile({
          displayName: displayName,
          email: email
        }).then(function() {
          toast("Profile Updated✅")
        }, function(error) {
          // An error happened.
        });
      }
    })
  }

  return (
    <section className="centerBlock">
    <div style={{display:"flex",flexWrap:"wrap"}}>
      <div>
        <img src={profilePageIcon} style={{width:"70%",maxWidth:"600px",padding:"30px"}} alt=""/>   
      </div>
      <div>
        <h2 style={{color:"#000",marginLeft:"10px",marginBottom:"30px"}}>Edit Profile</h2>
        <form className={classes.root} noValidate autoComplete="off">
          <CssTextField
            className={classes.margin}
            label="Full Name"
            variant="outlined"
            InputLabelProps={{
              classes: {
                root: classes.labelRoot,
                focused: classes.labelFocused
              }
            }}
            name="displayName"
            id="custom-css-outlined-input"
            value={displayName||user.displayName}
            onChange={handleDisplayNameChange}
          />
                      <CssTextField
            className={classes.margin}
            label=""
            variant="outlined"
            InputLabelProps={{
              classes: {
                root: classes.labelRoot,
                focused: classes.labelFocused
              }
            }}
            name="confirmValue"
            id="custom-css-outlined-input"
            value={user.phoneNumber}
            onChange={handleDisplayNameChange}
            disabled
          />
          <CssTextField
            className={classes.margin}
            label="Email"
            variant="outlined"
            InputLabelProps={{
              classes: {
                root: classes.labelRoot,
                focused: classes.labelFocused
              }
            }}
            name="email"
            id="custom-css-outlined-input"
            value={user.email}
            onChange={handleEmailChange}
          />
          <StyledButton style={{marginLeft:"8px",marginTop:"40px"}} onClick={SaveData} >Save</StyledButton>
        </form>
      </div>
    </div>
    </section>
  );
}

export default ProfilePage;
